import { initForms } from '@rcg/forms';
import { registerTemplaterViews } from '@rcg/views';

if (window.location.pathname.startsWith('/act/')) {
  const destPath = window.location.href.substring(window.location.origin.length + '/act'.length);
  const dest = `https://act.assist.si${destPath}`;

  document.body.innerHTML = '';

  const iframe = document.createElement('iframe');
  iframe.src = dest;
  iframe.style.position = 'fixed';
  iframe.style.top = '0';
  iframe.style.left = '0';
  iframe.style.width = '100%';
  iframe.style.height = '100%';
  iframe.style.border = 'none';

  document.body.appendChild(iframe);
} else {
  (async () => {
    await import('./sentry');
    const { platformBrowserDynamic } = await import('@angular/platform-browser-dynamic');

    const { registerLicense } = await import('@syncfusion/ej2-base');
    const { AppModule } = await import('./app/app.module');
    const { environment } = await import('./environments/environment');

    registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9edXVQRmBeU0N+W0c=');
    registerTemplaterViews();

    if (environment.production) {
      const { enableProdMode } = await import('@angular/core');
      enableProdMode();
    }

    initForms(environment.production);

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((window as unknown as { rcgAppBootstrapError: (e: unknown) => void }).rcgAppBootstrapError ?? console.error);
  })();
}
